@font-face {
  font-family: Terminus;
  src: url('/fonts/TerminusModern.ttf') format('truetype');
}

@font-face {
  font-family: Retro;
  src: url('/fonts/3270.ttf') format('truetype');
}

@font-face {
  font-family: C64;
  src: url('/fonts/C64.ttf') format('truetype');
}

@font-face {
  font-family: Apple2;
  src: url('/fonts/Apple2.ttf') format('truetype');
}